import React, { memo } from 'react'
import cx from 'clsx'
import { Modal, Box, StyledTypography } from '@kyc-ui/react'
import useTheme from 'src/hooks/common/useTheme'
import useTranslation from 'src/hooks/common/useTranslation'
import CustomizedImage from 'src/components/CustomizedImage'
import styles from './loading.module.scss'

interface Props {
  height?: number | string
  width?: number | string
  hide?: boolean
  isGlobal?: boolean
  globalLoadingMessage?: string
}

const Loading: React.FC<Props> = (props) => {
  const { hide = false, isGlobal = false, height = 64, width = 64, globalLoadingMessage } = props
  const theme = useTheme()
  const t = useTranslation('globalLoadingMessage')
  const loadingBox = () => (
    <Box
      className={isGlobal ? styles.loading : ''}
      tabIndex={-1}
      sx={{
        width,
        height,
        marginLeft: 'auto',
        marginRight: 'auto',
        color: theme.palette.primary.main,
        img: { width: height, height: height, borderRadius: '16px', display: 'block', margin: 'auto' },
        alignSelf: 'center'
      }}
    >
      {theme.images?.loading && <CustomizedImage image={theme.images.loading} />}
      <StyledTypography
        subtitle1
        className={cx(styles.message)}
        sx={{
          display: isGlobal && globalLoadingMessage ? 'flex' : 'none',
          width: {
            xs: '300px',
            sm: 'auto'
          },
          whiteSpace: {
            xs: 'pre-wrap',
            sm: 'nowrap'
          }
        }}
      >
        {globalLoadingMessage ? t(globalLoadingMessage) : ''}
      </StyledTypography>
    </Box>
  )

  return isGlobal ? (
    <Modal
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(3px)'
        }
      }}
      open={!hide}
    >
      {loadingBox()}
    </Modal>
  ) : hide ? (
    <></>
  ) : (
    loadingBox()
  )
}

export default memo(Loading)
