import { Search } from '@mui/icons-material'
import { EButtonType, TButton, TEntityStep, TGuideCard } from 'src/typings/entity'
import { EStepType } from 'src/constants/entity'

export const buttonGroup: Record<string, TButton> = {
  searchRegistry: {
    color: 'colorName.entityButton',
    text: 'searchRegistry',
    variant: 'outlined',
    buttonType: EButtonType.searchRegistry,
    width: '200px',
    icon: Search
  },
  tryNewSearch: {
    color: 'colorName.entityButton',
    text: 'tryNewSearch',
    variant: 'contained',
    buttonType: EButtonType.tryNewSearch
  },
  saveAndNext: {
    color: 'colorName.entityButton',
    text: 'saveAndNext',
    variant: 'contained',
    buttonType: EButtonType.saveAndNext,
    keyPrefix: 'btnText'
  },
  takePhoto: {
    color: 'colorName.entityButton',
    text: 'takePhoto',
    variant: 'contained',
    buttonType: EButtonType.takePhoto,
    keyPrefix: 'btnText'
  },
  iAmHappy: {
    color: 'colorName.entityButton',
    text: 'iAmHappy',
    variant: 'contained',
    buttonType: EButtonType.iAmHappy,
    keyPrefix: 'btnText'
  },
  tryAgain: {
    color: 'colorName.entityButton',
    text: 'tryAgain',
    variant: 'outlined',
    buttonType: EButtonType.tryAgain,
    keyPrefix: 'btnText'
  },
  iAmHome: {
    color: 'colorName.entityButton',
    text: 'iAmHome',
    variant: 'contained',
    buttonType: EButtonType.iAmHome,
    keyPrefix: 'btnText'
  },
  notHome: {
    color: 'colorName.entityButton',
    text: 'notHome',
    variant: 'outlined',
    buttonType: EButtonType.notHome,
    keyPrefix: 'btnText'
  },
  enterDetailsManually: {
    color: 'colorName.entityButton',
    text: 'enterDetailsManually',
    variant: 'outlined',
    buttonType: EButtonType.enterDetailsManually
  },
  previous: {
    color: 'colorName.entityButton',
    text: 'back',
    variant: 'outlined',
    buttonType: EButtonType.previous
  },
  back: {
    color: 'colorName.entityButton',
    text: 'back',
    buttonType: EButtonType.cancel
  },
  outlinedBack: {
    color: 'colorName.entityButton',
    text: 'back',
    variant: 'outlined',
    buttonType: EButtonType.cancel
  },
  next: {
    color: 'colorName.entityButton',
    text: 'next',
    variant: 'contained',
    buttonType: EButtonType.next
  },
  saveFormAndNext: {
    color: 'colorName.entityButton',
    text: 'next',
    variant: 'contained',
    buttonType: EButtonType.saveFormAndNext
  },
  begin: {
    color: 'colorName.entityButton',
    text: 'begin',
    variant: 'contained',
    buttonType: EButtonType.begin
  },
  openChild: {
    color: 'colorName.entityButton',
    text: 'openChild',
    variant: 'contained',
    buttonType: EButtonType.openChild
  },
  save: {
    color: 'colorName.entityButton',
    text: 'save',
    variant: 'contained',
    buttonType: EButtonType.save
  },
  addShareholderMember: {
    color: 'colorName.entityButton',
    text: 'addShareholderMember',
    variant: 'outlined',
    buttonType: EButtonType.addShareholderMember
  },
  saveAndAddAnother: {
    color: 'colorName.entityButton',
    text: 'saveAndAddAnother',
    variant: 'contained',
    buttonType: EButtonType.saveAndAddAnother
  },
  saveJointShareholderDetail: {
    color: 'colorName.entityButton',
    text: 'saveAndReturn',
    variant: 'contained',
    buttonType: EButtonType.saveAndReturn
  },
  saveAndAddMember: {
    color: 'colorName.entityButton',
    text: 'saveAndAddMember',
    variant: 'contained',
    buttonType: EButtonType.saveAndAddMember
  },
  saveAndReturn: {
    color: 'colorName.entityButton',
    text: 'saveAndReturn',
    variant: 'outlined',
    buttonType: EButtonType.saveAndReturn
  },
  cancel: {
    color: 'colorName.entityButton',
    text: 'cancel',
    buttonType: EButtonType.cancel
  },
  backToMain: {
    color: 'colorName.entityButton',
    text: 'cancel',
    buttonType: EButtonType.backToMain
  },
  completeSteps: {
    color: 'colorName.entityButton',
    text: 'completeSteps',
    variant: 'contained',
    buttonType: EButtonType.completeSteps
  },
  agreeAndUpload: {
    color: 'colorName.entityButton',
    text: 'agreeAndUpload',
    variant: 'contained',
    buttonType: EButtonType.agreeAndUpload
  },
  completeSubmission: {
    color: 'colorName.entityButton',
    text: 'completeSubmission',
    variant: 'contained',
    buttonType: EButtonType.completeSubmission
  }
}

export const identityWelcome: TEntityStep = {
  name: 'identity',
  stepType: EStepType.SectionMessage,
  cards: [
    {
      guideCard: {
        name: 'beforeWeBegin',
        description: 'beforeWeBeginDescription',
        subDescription: 'beforeWeBeginSubDescription',
        img: 'entityBeforeWeBegin'
      }
    },
    {
      buttons: [buttonGroup.begin]
    }
  ]
}

export const welcomeSteps: TEntityStep = {
  name: 'welcome',
  groupType: 'welcome',
  stepType: EStepType.SectionMessage,
  cards: [],
  subSteps: [identityWelcome]
}

export const applicantProfile: TEntityStep = {
  name: 'applicantProfile',
  groupType: 'applicantProfile',
  stepType: EStepType.SectionMessage,
  cards: [
    {
      guideCard: {
        name: 'sectionMessage.applicantProfile.title',
        description: 'sectionMessage.applicantProfile.mainMessage',
        suffix: 'sectionMessage.applicantProfile.suffix',
        img: 'entityApplicantProfile'
      }
    }
  ]
}

export const individualWelcome: TGuideCard = {
  name: 'beforeWeBegin.title',
  description: 'beforeWeBegin.mainMessage',
  subDescription: 'beforeWeBegin.subMessage',
  estimatedTime: 'beforeWeBegin.estimatedTime',
  img: 'individualBeforeWeBegin'
}

export const companyIdentity: TEntityStep = {
  name: 'identity',
  stepType: EStepType.SectionMessage,
  cards: [
    {
      guideCard: {
        name: 'sectionMessage.companyIdentity.title',
        description: 'sectionMessage.companyIdentity.mainMessage',
        suffix: 'sectionMessage.companyIdentity.suffix',
        img: 'entityCompanyIdentity'
      }
    },
    {
      buttonDirection: 'row',
      buttons: [buttonGroup.previous, buttonGroup.next]
    }
  ]
}

export const officersWelcome: TEntityStep = {
  name: 'officers',
  stepType: EStepType.SectionMessage,
  cards: [
    {
      guideCard: {
        name: 'sectionMessage.control.title',
        description: 'sectionMessage.control.',
        suffix: 'sectionMessage.control.suffix',
        img: 'entityControllingBodies'
      }
    },
    {
      buttonDirection: 'row',
      buttons: [buttonGroup.previous, buttonGroup.next]
    }
  ]
}

export const shareholdersWelcome: TEntityStep = {
  name: 'shareholders',
  stepType: EStepType.SectionMessage,
  cards: [
    {
      guideCard: {
        name: 'sectionMessage.ownership.title',
        description: 'sectionMessage.ownership.',
        suffix: 'sectionMessage.ownership.suffix',
        img: 'entityOwnership'
      }
    },
    {
      buttonDirection: 'row',
      buttons: [buttonGroup.previous, buttonGroup.next]
    }
  ]
}

export const personWithSignificantControlWelcome: TEntityStep = {
  name: 'personWithSignificantControl',
  stepType: EStepType.SectionMessage,
  cards: [
    {
      guideCard: {
        name: 'sectionMessage.psc.title',
        description: 'sectionMessage.psc.',
        suffix: 'sectionMessage.suffix',
        img: 'entityPsc'
      }
    },
    {
      buttonDirection: 'row',
      buttons: [buttonGroup.previous, buttonGroup.next]
    }
  ]
}

export const termsSteps: TEntityStep = {
  name: 'terms',
  stepType: EStepType.Root,
  cards: [],
  groupType: 'Terms',
  stepProgress: {
    step: 1,
    total: 3,
    isFinished: false,
    subtitle: 'terms'
  },
  subSteps: [
    {
      name: 'terms',
      stepType: EStepType.SectionMessage,
      cards: [
        {
          guideCard: {
            name: 'termsDeclaration',
            description: 'termsDescription'
          }
        },
        {
          buttonDirection: 'row',
          buttons: [buttonGroup.previous, buttonGroup.agreeAndUpload]
        }
      ]
    },
    {
      name: 'upload',
      stepProgressVisible: true,
      cards: [{}]
    }
  ]
}

export const uploadingSteps: TEntityStep = {
  name: 'uploading',
  stepType: EStepType.Root,
  cards: [],
  groupType: 'Terms',
  stepProgress: {
    step: 1,
    total: 3,
    isFinished: false,
    subtitle: 'uploading'
  },
  subSteps: [
    {
      name: 'upload',
      stepProgressVisible: false,
      cards: [{}]
    }
  ]
}
