import { compact } from 'lodash'
import { actions as entitySliceActions } from 'src/state/entity/slice'
import { camelCaseKeys } from 'src/utils/stringHelper'
import {
  ICaseEntity,
  IConfigurationSetting,
  ISearchItem,
  ISignalrMessage,
  IStepList,
  IStepListResult,
  TCompanyType,
  TScaffold
} from 'src/typings/entity'
import * as EntityUploadPortalService from 'src/services/EntityUploadPortalService'
import { EMEMBER_TYPE, ENTITY_DIRECTIVE_NAME, EStepType } from 'src/constants/entity'
import storage from 'src/utils/storage'
import documentRequest from 'src/state/rtkQueries/documentRequest'
import { uploadPortalApiSlice, getIsDocumentValidation, getIsMyInfoEntity } from './uploadPortalApiSlice'

const clearErrorMessagesForMyInfoEntity = (result?: IStepListResult) => {
  if (getIsMyInfoEntity() && result) {
    return {
      ...result,
      hasErrorMessage: false,
      steps: {
        ...result.steps,
        list: result.steps.list.map((item) => ({ ...item, errorMessages: undefined }))
      }
    }
  } else {
    return result
  }
}
const entityRequest = uploadPortalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConfigurationSettings: builder.query({
      query: (params) => ({
        url: `CaseRequest/configuration-settings?${params?.settings
          ?.map((setting: string) => 'settings=' + setting)
          .join('&')}`,
        method: 'GET'
      }),
      transformResponse: (data: { properties: IConfigurationSetting[] }) => {
        return data.properties.reduce((acc, next) => {
          acc[next.name] = next
          return acc
        }, {} as Record<string, IConfigurationSetting>)
      }
    }),
    getScaffolds: builder.query({
      query: () => {
        return {
          url: process.env.REACT_APP_ENABLE_MOCK
            ? 'CaseRequest/scaffolds'
            : `CaseRequest/scaffolds?isDocumentValidation=${getIsDocumentValidation()}`,
          method: 'GET'
        }
      },
      // eslint-disable-next-line no-empty-pattern
      async onQueryStarted({ isDocumentValidation = getIsDocumentValidation() }, { dispatch, queryFulfilled }) {
        const isMyInfoEntity = getIsMyInfoEntity()
        try {
          const { data } = await queryFulfilled
          const entitySteps = EntityUploadPortalService.calculateFullNameOfEntitySteps(
            EntityUploadPortalService[isMyInfoEntity ? 'generateMyInfoRootSteps' : 'generateRootSteps'](
              data.map((step: TScaffold) => camelCaseKeys(step)),
              isDocumentValidation
            )
          )
          dispatch(entitySliceActions.updateEntitySteps(entitySteps))
          dispatch(entityRequest.endpoints.getAllJourneys.initiate({ isCompany: true }))
          dispatch(entityRequest.endpoints.getAllJourneys.initiate({ isCompany: false }))
          if (!isDocumentValidation && !isMyInfoEntity) {
            dispatch(entityRequest.endpoints.getAddStepTabs.initiate({ isCompany: true }))
            dispatch(entityRequest.endpoints.getAddStepTabs.initiate({ isCompany: false }))
          }
          dispatch(entityRequest.endpoints.getCompanyTypes.initiate({}))
          dispatch(entityRequest.endpoints.getCompanyStatuses.initiate({}))
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getStepList: builder.query({
      query: (params) => {
        return {
          url: process.env.REACT_APP_ENABLE_MOCK ? 'CaseRequest/stepList' : `CaseRequest/${params.id}/stepList`,
          method: 'GET',
          params: {
            ...params,
            isMyInfo: getIsMyInfoEntity(),
            isApplicant: false,
            isDocumentValidation: getIsDocumentValidation(),
            isIndividual: false
          }
        }
      },
      providesTags: (result, error, arg) => {
        const list = result?.steps?.list
        const tag = {
          type: 'EntityStepList' as const,
          id: arg.id
        }
        return list
          ? [
              ...list.map((item: IStepList) => ({
                type: 'EntityStepList' as const,
                id: item.step.caseStepId
              })),
              tag,
              {
                type: 'EntityStepList',
                id: 'LIST'
              }
            ]
          : [
              tag,
              {
                type: 'EntityStepList',
                id: 'LIST'
              }
            ]
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: rawResult = {} } = await queryFulfilled

          const result = (clearErrorMessagesForMyInfoEntity(rawResult) || {}) as IStepListResult
          dispatch(
            entitySliceActions.updateSubSteps({
              groupId: id,
              result
            })
          )
          dispatch(
            entitySliceActions.clearRefreshMembers({
              type: EStepType.MemberList,
              id
            })
          )

          const { steps: { list = [] } = {} } = result || {}
          list.forEach((item: IStepList) => {
            if (item.directiveName === ENTITY_DIRECTIVE_NAME.identityCompany && item.step.caseStepId) {
              dispatch(entitySliceActions.updateOfficersStatus({ identity: false }))
              dispatch(
                entityRequest.endpoints.getCompanyIdentity.initiate({
                  id: item.step.caseStepId,
                  groupId: id,
                  timestamp: Date.now()
                })
              )
            }
            if (item.directiveName === ENTITY_DIRECTIVE_NAME.kycDocuments && item.step.caseStepId) {
              dispatch(entitySliceActions.updateOfficersStatus({ document: false }))
              dispatch(
                documentRequest.endpoints.getDocuments.initiate({
                  id: item.step.caseStepId,
                  groupId: id,
                  timestamp: Date.now()
                })
              )
            }
          })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getApplicantStep: builder.query({
      query: (params) => {
        return {
          url: process.env.REACT_APP_ENABLE_MOCK ? 'CaseRequest/stepList' : `CaseRequest/${params.id}/stepList`,
          method: 'GET',
          params: {
            ...params,
            isMyInfo: true,
            isApplicant: true,
            isDocumentValidation: getIsDocumentValidation(),
            isIndividual: false
          }
        }
      },
      transformResponse: (data: { steps?: { list: IStepList[] } }) => {
        const steps = data?.steps?.list ?? []
        return steps.find((step: IStepList) => step?.step?.title === 'AUTHORISED REPRESENTATIVE')
      }
    }),
    getApplicantMember: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_ENABLE_MOCK
          ? 'CaseRequest/caseCompanyMember'
          : `CaseRequest/${params.stepId}/caseCompanyMember`,
        method: 'GET',
        params: {
          memberId: params.stepId,
          isMyInfo: true,
          isDocumentValidation: false
        }
      }),
      providesTags: ['ApplicantProfile']
    }),
    getStepListPermissions: builder.query({
      query: (params) => {
        return {
          url: process.env.REACT_APP_ENABLE_MOCK ? 'CaseRequest/stepList' : `CaseRequest/${params.id}/stepList`,
          method: 'GET',
          params: {
            ...params,
            isDocumentValidation: getIsDocumentValidation(),
            isIndividual: false
          }
        }
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: result = {} } = await queryFulfilled
          dispatch(
            entitySliceActions.updateSubStepsPermissions({
              groupId: id,
              result
            })
          )
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getCompanyIdentity: builder.query({
      query: (params) => {
        return {
          url: process.env.REACT_APP_ENABLE_MOCK
            ? 'CaseRequest/companyIdentity'
            : `CaseRequest/${params.id}/companyIdentity`,
          method: 'GET',
          params: {
            ...params,
            isDocumentValidation: getIsDocumentValidation()
          }
        }
      },
      providesTags: ['EntityMember', 'CompanyIdentity'],
      async onQueryStarted({ id, groupId }, { dispatch, queryFulfilled }) {
        try {
          const { data: result = {} } = await queryFulfilled
          if (result) {
            dispatch(
              entitySliceActions.updateIdentitySteps({
                stepId: id,
                groupId,
                result: EntityUploadPortalService.assignIdentityPropertyValues(
                  { ...result, type: EMEMBER_TYPE.COMPANY },
                  groupId,
                  true
                )
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        } finally {
          dispatch(entitySliceActions.updateOfficersStatus({ identity: true }))
        }
      }
    }),
    getCaseCompanyMember: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_ENABLE_MOCK
          ? 'CaseRequest/caseCompanyMember'
          : `CaseRequest/${params.stepId}/caseCompanyMember`,
        method: 'GET',
        params: {
          memberId: params.stepId,
          isDocumentValidation: getIsDocumentValidation()
        }
      }),
      providesTags: (result, error, arg) => [
        {
          type: 'EntityMember' as const,
          id: arg.stepId
        }
      ],
      async onQueryStarted({ stepId, groupId, result: prevResult }, { dispatch, queryFulfilled }) {
        try {
          const { data: result = {} } = await queryFulfilled
          const mergedResult = Object.assign({}, result, prevResult)
          if (mergedResult) {
            dispatch(
              entitySliceActions.updateMemberCards({
                stepId,
                groupId,
                result: EntityUploadPortalService.assignIdentityPropertyValues(mergedResult, groupId)
              })
            )
            dispatch(
              entitySliceActions.clearRefreshMembers({
                type: EStepType.Detail,
                id: stepId
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getCaseStepGroup: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_ENABLE_MOCK ? 'CaseRequest/caseStepGroup' : `CaseRequest/${params.id}/caseStepGroup`,
        method: 'GET',
        params: {
          ...params,
          isDocumentValidation: getIsDocumentValidation()
        }
      }),
      providesTags: (result, error, arg) => [
        {
          type: 'EntityJointShareholder' as const,
          id: arg.id
        }
      ],
      async onQueryStarted({ id, groupId }, { dispatch, queryFulfilled }) {
        try {
          const { data: result = {} } = await queryFulfilled
          if (result) {
            dispatch(
              entitySliceActions.updateShareholderGroup({
                stepId: id,
                groupId,
                result
              })
            )
            dispatch(
              entitySliceActions.clearRefreshMembers({
                type: EStepType.ShareholderGroup,
                id
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getValidation: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_ENABLE_MOCK
          ? 'CaseRequest/validation'
          : `CaseRequest/validation/${params.allowEdit}/${params.sectionId}`,
        method: 'GET',
        params
      })
    }),
    getAddStepTabs: builder.query({
      query: (params: { isCompany: boolean }) => ({
        url: 'CaseRequest/getAddStepTabs',
        method: 'GET',
        params
      }),
      async onQueryStarted({ isCompany }, { dispatch, queryFulfilled }) {
        try {
          const { data: result = {} } = await queryFulfilled
          if (result) {
            dispatch(
              entitySliceActions.updateAddStepTabs({
                isCompany,
                result
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getAddStepLinkProperties: builder.query({
      query: (params: { isCompany: boolean; groupName: string; groupId: number }) => ({
        url: 'CaseRequest/getAddStepLinkProperties',
        method: 'GET',
        params
      }),
      async onQueryStarted({ isCompany, groupName, groupId }, { dispatch, queryFulfilled }) {
        try {
          const { data: { result } = {} } = await queryFulfilled
          if (result) {
            dispatch(
              entitySliceActions.updateAddStepLinkProperties({
                isCompany,
                groupId,
                result
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getCompanyTypes: builder.query({
      query: () => 'CaseRequest/companyTypes',
      transformResponse: (data: TCompanyType) => {
        return Object.keys(data).map((key) => ({
          option: key,
          text: data[key]
        }))
      },
      async onQueryStarted(__, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          storage.setDropDownOptions({
            ...storage.getDropDownOptions(),
            CompanyTypes: data
          })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getCompanyStatuses: builder.query({
      query: () => 'CaseRequest/companyStatus',
      transformResponse: (data: string[]) => {
        return compact(
          data.map((key) => {
            if (key) {
              return {
                option: key
              }
            }
            return undefined
          })
        )
      },
      async onQueryStarted(__, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          storage.setDropDownOptions({
            ...storage.getDropDownOptions(),
            CompanyStatuses: data
          })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getAllJourneys: builder.query({
      query: (params: { isCompany: boolean }) => ({
        url: 'CaseRequest/allJourneys',
        method: 'GET',
        params
      }),
      async onQueryStarted({ isCompany }, { dispatch, queryFulfilled }) {
        try {
          const { data: result = {} } = await queryFulfilled
          if (result) {
            dispatch(
              entitySliceActions.updateAllJourneys({
                isCompany,
                result
              })
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getNationalitiesWithJurisdictions: builder.query({
      query: () => 'CaseRequest/nationalitiesWithJurisdictions',
      async onQueryStarted(__, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(entitySliceActions.updateNationalitiesWithJurisdictions(data))
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    lookupCases: builder.query({
      query: (params) => ({
        url: 'CaseRequest/lookupCases',
        method: 'GET',
        params
      }),
      providesTags: ['LookupCases'],
      async onQueryStarted({ page }, { dispatch, queryFulfilled }) {
        try {
          dispatch(entitySliceActions.updateSelectedExistsCase(undefined))
          const { data } = await queryFulfilled
          if (data) {
            dispatch(entitySliceActions.updateLookupCases(data))
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    getCase: builder.query({
      query: (params) => ({
        url: 'CaseRequest/getCase',
        method: 'GET',
        params
      }),
      async onQueryStarted({ page }, { dispatch, queryFulfilled }) {
        try {
          dispatch(entitySliceActions.updateSelectedExistsCase(undefined))
          const { data } = await queryFulfilled
          if (data?.result) {
            dispatch(entitySliceActions.updateSelectedExistsCase(data.result))
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    }),
    asyncGetEntities: builder.mutation({
      query: (body) => ({
        url: 'CaseRequest/asyncGetEntities',
        method: 'POST',
        body
      }),
      transformResponse: (data: ISignalrMessage) => {
        return data?.data?.map((entity: ICaseEntity) => ({
          id: entity.code,
          status: entity.inc,
          name: entity.name,
          nameTrans: entity.nameTrans,
          code: entity.code,
          displayCode: entity.displayCode || entity.code,
          address: entity.add,
          isMatchingCase: false,
          internalId: entity.InternalId,
          countryCode: entity.countryCode,
          countryFlag: entity.countryFlag,
          countryId: entity.countryId,
          jurisdictionSource: entity.jurisdictionSource,
          inc: entity.inc,
          type: entity.type,
          add: entity.add
        })) as ISearchItem[]
      }
    }),
    validation: builder.mutation({
      query: (params) => ({
        url: 'CaseRequest/validation',
        method: 'GET',
        params: {
          ...params,
          isDocumentValidation: getIsDocumentValidation()
        }
      })
    }),
    scaffoldsDocumentsValidation: builder.mutation({
      query: () => ({
        url: 'CaseRequest/scaffolds?isDocumentValidation=true',
        method: 'GET'
      }),
      transformResponse: (data: TScaffold[]) => {
        return { data: data.filter((item) => !!item.missingDocumentCount) }
      }
    }),
    validator: builder.mutation({
      query: (body) => ({
        url: 'CaseRequest/validator',
        method: 'POST',
        body
      })
    }),
    submit: builder.mutation({
      query: () => ({
        url: 'CaseRequest/submit',
        method: 'POST'
      })
    })
  }),
  overrideExisting: false
})

export default entityRequest
export const {
  useGetConfigurationSettingsQuery,
  useGetScaffoldsQuery,
  useGetStepListQuery,
  useGetApplicantStepQuery,
  useGetApplicantMemberQuery,
  useGetNationalitiesWithJurisdictionsQuery,
  useGetCaseCompanyMemberQuery,
  useGetCaseStepGroupQuery,
  useGetAddStepLinkPropertiesQuery,
  useLookupCasesQuery,
  useAsyncGetEntitiesMutation,
  useValidationMutation,
  useScaffoldsDocumentsValidationMutation,
  useSubmitMutation
} = entityRequest
