import { createSlice } from '@reduxjs/toolkit'
import {
  TEntityStep,
  THashEntityStepsFullNames,
  TStepProgress,
  IMemberTab,
  IMemberProperty,
  IJourney,
  IMemberResult,
  TRefreshMembers
} from 'src/typings/entity'
import { DropDownOption, EFieldType } from 'src/typings/types'
import { TCaseDetail } from 'src/typings/user'
import storage from 'src/utils/storage'
import { EStepType } from 'src/constants/entity'
import reducers from './reducers'

export interface State {
  currentStepName: string
  currentStepProgress?: TStepProgress
  currentRootStep?: TEntityStep
  previousRootSteps?: TEntityStep[]
  currentStep?: TEntityStep
  entitySteps: TEntityStep[]
  hashEntityStepsFullNames?: THashEntityStepsFullNames
  companyAddStepTabs?: IMemberTab[]
  individualAddStepTabs?: IMemberTab[]
  companyAddStepTabsRaw?: IMemberTab[]
  individualAddStepTabsRaw?: IMemberTab[]
  companyAddStepLinkProperties?: Record<number, IMemberProperty[]>
  individualAddStepLinkProperties?: Record<number, IMemberProperty[]>
  shareholderGroupProperties: IMemberTab[]
  lookupCasesOptions: DropDownOption[]
  lookupCasesTotalCount: number
  companyJourneys: IJourney[]
  individualJourneys: IJourney[]
  identityStatus: Record<string, boolean>
  companyDetail: TCaseDetail | null
  // The caseCommonId comes from the companyIdentity endpoint.
  caseCommonId?: number
  selectedExistsCase?: IMemberResult
  defaultJourneyId: {
    company?: number
    individual?: number
  }
  identityDocumentChanged?: number
  refreshMembers: TRefreshMembers
  pageIndex: number
  isFetchingList: boolean
}

const initialState = {
  currentStepName: '',
  entitySteps: [],
  shareholderGroupProperties: [
    {
      name: 'details',
      properties: [
        {
          id: 1,
          fieldName: 'ShareCount',
          fieldType: EFieldType.ShareCount,
          isMandatory: true,
          isEditable: true,
          fieldValue: '',
          label: 'shareCount'
        },
        {
          id: 2,
          fieldName: 'GroupName',
          fieldType: EFieldType.Text,
          isMandatory: true,
          isEditable: true,
          fieldValue: '',
          label: 'groupName'
        }
      ] as unknown as IMemberProperty[]
    }
  ] as unknown as IMemberTab[],
  companyJourneys: [],
  individualJourneys: [],
  lookupCasesOptions: [],
  lookupCasesTotalCount: 0,
  identityStatus: {
    identity: false,
    document: true
  },
  companyDetail: storage.getLoginData()?.companyDetail,
  defaultJourneyId: {},
  identityDocumentChanged: 0,
  pageIndex: 0,
  refreshMembers: {
    [EStepType.MemberList]: [],
    [EStepType.Detail]: [],
    [EStepType.Document]: [],
    [EStepType.ShareholderGroup]: [],
    [EStepType.JointShareholderDocument]: []
  },
  isFetchingList: false
} as State

const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers
})

export default entitySlice
export const actions = entitySlice.actions
