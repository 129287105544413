import React, { useState, MouseEvent } from 'react'
import { Language } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Typography, Box, Tooltip, useTheme } from '@kyc-ui/react'
import { languageOptions } from 'src/utils/i18n'
import { LANGUAGES } from 'src/constants/app'
import useTranslation, { useI18N } from 'src/hooks/common/useTranslation'

const I18N = () => {
  const theme = useTheme()
  const i18n = useI18N()
  const tc = useTranslation('common')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeLanguage = async (lng: typeof languageOptions[number]['value']) => {
    await i18n.changeLanguage(lng)
    handleClose()
  }
  return (
    <Box>
      <Tooltip placement="left" title={tc('language') ?? 'Language'}>
        <IconButton
          aria-controls="languageMenu-appbar"
          aria-haspopup="true"
          aria-label="Language Options"
          color="inherit"
          data-element="language-button"
          onClick={handleMenu}
        >
          <Language />
        </IconButton>
      </Tooltip>
      <Menu
        slotProps={{
          paper: {
            sx: {
              width: '280px',
              marginTop: '35px',
              py: 2,
              boxShadow:
                '0px 5px 5px -3px rgba(128, 148, 163, 0.2), 0px 8px 10px 1px rgba(100, 116, 139, 0.14), 0px 3px 14px 2px rgba(100, 116, 139, 0.12)!important'
            }
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        id="languageMenu-appbar"
        keepMounted
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {languageOptions.map((item, idx) => (
          <MenuItem
            key={idx}
            selected={item.value === i18n.language}
            onClick={() => handleChangeLanguage(item.value)}
            sx={{
              py: 1,
              minHeight: 'auto',
              '&.Mui-selected': { backgroundColor: theme.palette.grey['100'] }
            }}
          >
            {item.value === 'en' && <Typography variant="body1">{item.label}</Typography>}
            {item.value !== 'en' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Typography variant="body1">{LANGUAGES[item.value]}</Typography>
                <Typography variant="body1">{item.label}</Typography>
              </Box>
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default I18N
